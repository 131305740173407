/* eslint-disable class-methods-use-this */
import * as AppStateActions from './app-state.actions';
import * as interfaces from './interfaces';

import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';

import { Api } from '../../../api/src/Api';
import { Router } from '@angular/router';
import { Station } from './interfaces';

export interface AppStateModel {
    userData: interfaces.UserData | false | undefined;

    // * data-state
    transportAuthorities: interfaces.TransportAuthority[];
    policies?: interfaces.Policy[];
    faqs?: interfaces.Faq[];
    tickets: interfaces.Ticket[];
    transportZones: interfaces.TransportZone[];
    products: interfaces.Product[];
    selectedTransportAuthority: any;
    suppliers: interfaces.Supplier[];
    stations: interfaces.Station[];
    pickupPoints: interfaces.Station[];

    // * ui-state
    showHeaderTransportAuthoritiesDropdown: boolean;
    showChooseTransportAuthorityDialog: boolean;
    cartHidden: boolean;
}

export const APP_STATE_TOKEN = new StateToken<AppStateModel>(`app`);

@State<AppStateModel>({
    name: APP_STATE_TOKEN,
    defaults: {
        userData: undefined,

        // * data-state
        transportAuthorities: [],
        policies: undefined,
        faqs: undefined,
        tickets: [],
        transportZones: [],
        selectedTransportAuthority: undefined,
        products: [],
        suppliers: [],
        stations: [],
        pickupPoints: [],

        // * ui-state
        showHeaderTransportAuthoritiesDropdown: true,
        showChooseTransportAuthorityDialog: false,
        cartHidden: true,
    },
})
@Injectable()
export class AppState {
    constructor(private readonly apiService: Api,
                private readonly router: Router,
                private readonly ngZone: NgZone) {
    }

    @Selector()
    static userData(state: AppStateModel) {
        return state.userData;
    }

    @Selector()
    static tickets(state: AppStateModel) {
        return state.tickets;
    }

    @Selector()
    static products(state: AppStateModel) {
        return state.products;
    }

    @Selector()
    static suppliers(state: AppStateModel) {
        return state.suppliers;
    }

    @Selector()
    static stations(state: AppStateModel) {
        return state.stations;
    }

    @Selector()
    static pickupPoints(state: AppStateModel) {
        return state.pickupPoints;
    }

    @Selector()
    static transportAuthorities(state: AppStateModel) {
        return state.transportAuthorities;
    }

    @Selector()
    static policies(state: AppStateModel) {
        return state.policies;
    }

    @Selector()
    static faqs(state: AppStateModel) {
        return state.faqs;
    }

    @Selector()
    static cartHidden(state: AppStateModel) {
        return state.cartHidden;
    }

    @Selector()
    static selectedTransportAuthority(state: AppStateModel) {
        return state.selectedTransportAuthority;
    }

    @Selector()
    static showHeaderTransportAuthoritiesDropdown(state: AppStateModel) {
        return state.showHeaderTransportAuthoritiesDropdown;
    }

    @Selector()
    static showChooseTransportAuthorityDialog(state: AppStateModel) {
        return state.showChooseTransportAuthorityDialog;
    }

    @Selector()
    static serviceMode(state: AppStateModel) {
        return state.transportAuthorities ? state.transportAuthorities[0].mode : true;
    }

    @Action(AppStateActions.Get)
    async get(ctx: StateContext<AppStateModel>) {
        const res =
            await this.apiService.transportAuthorities.transportAuthoritiesControllerGetAllTransportAuthorities();
        const transportAuthorities = res.data as any;

        ctx.patchState({
            transportAuthorities,
        });
    }

    @Action(AppStateActions.GetPolicies)
    async getPolicies(ctx: StateContext<AppStateModel>) {
        const res = await this.apiService.users.usersControllerGetAllPolicies();
        const policies = res.data as any;

        ctx.patchState({
            policies,
        });
    }

    @Action(AppStateActions.GetFaqs)
    async getFaqs(ctx: StateContext<AppStateModel>) {
        const state = ctx.getState();

        if (!state.selectedTransportAuthority) {
            return;
        }

        const res = await this.apiService.users.usersControllerGetFaq(state.selectedTransportAuthority.id);
        const faqs = res.data as any;

        ctx.patchState({
            faqs,
        });
    }

    @Action(AppStateActions.GetTickets)
    async getTickets(ctx: StateContext<AppStateModel>) {
        const state = ctx.getState();

        if (!state.selectedTransportAuthority) {
            return;
        }

        const res = await this.apiService.users.usersControllerGetAllTickets(state.selectedTransportAuthority.id);

        const tickets = res.data as any;

        ctx.patchState({
            tickets,
        });
    }

    @Action(AppStateActions.GetProducts)
    async getProducts(ctx: StateContext<AppStateModel>) {
        const { data } = await this.apiService.products.productsControllerAllProducts();

        ctx.patchState({
            products: data,
        });
    }

    @Action(AppStateActions.GetSuppliers)
    async getSuppliers(ctx: StateContext<AppStateModel>) {
        const { data } = await this.apiService.users.usersControllerGetSuppliers();

        ctx.patchState({
            suppliers: data,
        });
    }

    @Action(AppStateActions.GetStations)
    async getStations(ctx: StateContext<AppStateModel>) {
        const { data }: { data: Station[] } = await this.apiService.users.usersControllerGetBusStopList();

        ctx.patchState({
            stations: data.sort((first, second) => first.lp - second.lp),
        });
    }

    @Action(AppStateActions.GetPickupPoints)
    async getPickupPoints(ctx: StateContext<AppStateModel>) {
        const { data }: { data: Station[] } = await this.apiService.users.usersControllerGetPickupPoints();

        ctx.patchState({
            pickupPoints: data.sort((first, second) => first.lp - second.lp),
        });
    }

    @Action(AppStateActions.GetUser)
    async getUser(ctx: StateContext<AppStateModel>) {
        const token = localStorage.getItem(`token`) ?? ``;

        if (!token) {
            ctx.patchState({
                userData: false,
            });
            return;
        }

        const res = await this.apiService.users
            .usersControllerGetMe({
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .catch(() => {
                ctx.patchState({
                    userData: false,
                });
            });

        if (!res) {
            return;
        }

        const userData = res.data as any;

        ctx.patchState({
            userData,
        });
    }

    @Action(AppStateActions.Select)
    async selectTransportAuthority(ctx: StateContext<AppStateModel>, options: AppStateActions.Select) {
        const state = ctx.getState();

        const selectedTransportAuthority = state.transportAuthorities.find(
            (transportAuthority: interfaces.TransportAuthority) => {
                return transportAuthority.slug === options.slug;
            },
        );

        if (selectedTransportAuthority) {
            localStorage.setItem(`transport-authority`, selectedTransportAuthority.slug);
        }

        ctx.patchState({
            selectedTransportAuthority,
        });
    }

    @Action(AppStateActions.Deduce)
    async deduceTransportAuthority(ctx: StateContext<AppStateModel>) {
        const { transportAuthorities } = ctx.getState();

        const urlParts = this.router.url.split(`/`);
        const [, slug] = urlParts;

        const localSlug = localStorage.getItem(`transport-authority`);
        const validUrl = [`faq`, `sklep`, `uzytkownik`, `kalkulator`, `kontakt`, `regulamin`, `admin`];
        const splitUrl = this.router.url.split(`/`);

        if (!slug && !localSlug) {
            if (transportAuthorities.length === 1) {
                const [selectedTransportAuthority] = transportAuthorities;
                ctx.patchState({
                    selectedTransportAuthority,
                });

                localStorage.setItem(`transport-authority`, selectedTransportAuthority.slug);

                if (!validUrl.includes(splitUrl[1])) {
                    this.ngZone.run(() => {
                        void this.router.navigate([`/${selectedTransportAuthority.slug}`]);
                    });
                }
            }
            ctx.patchState({
                showChooseTransportAuthorityDialog: true,
            });
            return;
        }

        let selectedTransportAuthority = transportAuthorities.find((transportAuthority: any) => {
            return transportAuthority.slug === slug;
        });

        if (localSlug && this.router.url === `/`) {
            selectedTransportAuthority = transportAuthorities.find((transportAuthority: any) => {
                return transportAuthority.slug === localSlug;
            });
        }

        // Handle pages like /faq /contact.
        if (!selectedTransportAuthority && this.router.url !== `/`) {
            const localStorageSelectedTransportAuthority = transportAuthorities.find(
                (transportAuthority: interfaces.TransportAuthority) => {
                    return transportAuthority.slug === localSlug;
                },
            );

            selectedTransportAuthority = localStorageSelectedTransportAuthority;

            if (localStorageSelectedTransportAuthority) {
                ctx.patchState({
                    selectedTransportAuthority: localStorageSelectedTransportAuthority,
                });
                if (!validUrl.includes(splitUrl[1])) {
                    this.ngZone.run(() => {
                        void this.router.navigate([`/${selectedTransportAuthority?.slug}`]);
                    });
                }
            }

            if (!localStorageSelectedTransportAuthority && !validUrl.includes(splitUrl[1])) {
                [selectedTransportAuthority] = transportAuthorities;
                localStorage.setItem(`transport-authority`, selectedTransportAuthority.slug);
                this.ngZone.run(() => {
                    void this.router.navigate([`/${selectedTransportAuthority?.slug}`]);
                });
                return;
            }

            if (!selectedTransportAuthority) {
                [selectedTransportAuthority] = transportAuthorities;
                localStorage.setItem(`transport-authority`, selectedTransportAuthority.slug);

                ctx.patchState({
                    selectedTransportAuthority,
                    showChooseTransportAuthorityDialog: true,
                });
                return;
            }
            return;
        }

        // If detected by url slug.
        if (selectedTransportAuthority) {
            if (localSlug !== selectedTransportAuthority.slug) {
                localStorage.setItem(`transport-authority`, selectedTransportAuthority.slug);
            }
        }

        // If detected by localStorage.
        if (slug !== selectedTransportAuthority?.slug) {
            this.ngZone.run(() => {
                void this.router.navigate([`/${selectedTransportAuthority?.slug}`]);
            });
        }

        ctx.patchState({
            selectedTransportAuthority,
        });
    }

    @Action(AppStateActions.ShowHeaderDropdown)
    async showHeaderTransportAuthoritiesDropdown(
        ctx: StateContext<AppStateModel>,
        { show }: AppStateActions.ShowHeaderDropdown,
    ) {
        ctx.patchState({
            showHeaderTransportAuthoritiesDropdown: show,
        });
    }

    @Action(AppStateActions.ShowChooseDialog)
    async showChooseTransportAuthorityDialog(
        ctx: StateContext<AppStateModel>,
        { show }: AppStateActions.ShowChooseDialog,
    ) {
        ctx.patchState({
            showChooseTransportAuthorityDialog: show,
        });
    }

    @Action(AppStateActions.HideCart)
    async hideCart(ctx: StateContext<AppStateModel>, { hide }: AppStateActions.HideCart) {
        ctx.patchState({
            cartHidden: hide,
        });
    }
}
