/* eslint-disable */
/* tslint:disable */

/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Inject, Injectable } from '@angular/core';
import { CURRENT_LANG } from '../../shared/tokens/lang.tokens';
import { environment } from '../../../environments/environment';
import { ShoppingListItem } from '../../features/modules/state/interfaces';
import {ClearShoppingList, UserOrder} from "../api.interfaces";

export interface UpdateInvoiceEmailInput {
    email: string;
}

export interface UpdateTermsInput {
    content: string;
}

export interface CredentialsInput {
    email: string;
    password: string;
    captchaToken: string;
}

export interface RegistrationInput {
    email: string;
    password: string;
    policies: any[];
    captchaToken: string;
    guestRegister?: boolean
}

export interface UpdatePasswordInput {
    password: string;
    oldPassword: string;
}

export interface UpdateEmailInput {
    password: string;
    token: string;
}

export interface ResetPasswordInput {
    password: string;
}

export interface Guest{
    email: string
}

export interface CustomerInfoInput {
    name: string;
    city: string;
    firstName: string;
    lastName: string;
    line1: string;
    postalCode: string;
    phoneNumber?: string;
    line2?: string;
    companyName?: string;
    vatNumber?: string;
    type?: string;
}

export interface CustomerInfoUpdateInput {
    type?: string;
    name: string;
    city: string;
    firstName: string;
    lastName: string;
    line1: string;
    postalCode: string;
    phoneNumber?: string;
    line2?: string;
    companyName?: string;
    vatNumber?: string;
}

export interface TicketCarrierInput {
    cardName: string;
    cardNumber: string;
    cardOwner: string;
    cardExpiration: string;
    config?: string;
}

export interface UpdateTicketCarrierInput {
    name?: string;
    cardNumber?: string;
    cardOwner?: string;
    cardExpiration?: string;
    config?: string;
}

export interface UpdateShoppingList {
    transportAuthorityId: string;
    items: ShoppingListItem[];
}

export interface ResetEmailInput {
    email: string;
}

export interface SendUpdateEmailInput {
    email: string;
}

export interface ContactEmailInput {
    name: string;
    email: string;
    topic: string;
    content: string;
    captchaToken: string;
}

export interface ServiceModeBody {
    mode: boolean;
}

export interface SystemConfigurationInput {
    SMTP_CLIENT_SENDER: string;
    SMTP_CLIENT_HOST: string;
    SMTP_CLIENT_USERNAME: string;
    SMTP_CLIENT_PASSWORD: string;
    SMTP_CLIENT_PORT?: string;
}

export interface InvoiceDetailsInput {
    formVariant: string;
    formPurpose: string;
    formCode: string;
    defaultCurrencyCode: string;
    officeCode: string;
    nip: string;
    fullName: string;
    regon: string;
    countryCode: string;
    province: string;
    county: string;
    municipality: string;
    street: string;
    houseNumber: string;
    city: string;
    postalCode: string;
    postOffice: string;
    vatRate: string;
    mask: string;
}

export interface CreateTicketInput {
    name: string;
    duration: string;
    metadata: any;
    referenceNumber: number;
    canActivateFrom: any;
    canActivateTo: any;
    displayedFrom: any;
    displayedTo: any;
    inSaleFrom: any;
    inSaleTo: any;
    validFrom: any;
    validTo: any;
    priceWithTax: number;
    priceWithoutTax: number;
    isBearer: boolean;
    changeAllowed: boolean;
    config: any;
    customDurationRangeId?: string;
    tags: string[];
    transportLines: string[];
    transportZones: string[];
}

export interface UpdateTicketInput {
    name?: string;
    duration?: string;
    metadata?: any;
    referenceNumber?: number;
    canActivateFrom?: any;
    canActivateTo?: any;
    displayedFrom?: any;
    displayedTo?: any;
    inSaleFrom?: any;
    inSaleTo?: any;
    validFrom?: any;
    validTo?: any;
    priceWithTax?: number;
    priceWithoutTax?: number;
    isBearer?: boolean;
    changeAllowed?: boolean;
    config?: any;
    customDurationRangeId?: string;
    tags: string[];
    transportLines: string[];
    transportZones: string[];
}

export interface UpdateProductInput {
    name: string;
    nameEn: string;
    description: string;
    descriptionEn: string;
    code: string;
    priceWithTax: 0;
    priceWithoutTax: 0;
    file: string;
    isActive: true;
}

export interface CreateSupplierInput {
    name: string;
    nameEn: string;
    isActive: boolean;
    description: string;
    descriptionEn: string;
    price: number;
    type: string;
}

export interface UpdateSupplierInput {
    name: string;
    nameEn: string;
    isActive: boolean;
    description: string;
    descriptionEn: string;
    price: number;
    type: string;
}

export interface CreateTagGroupInput {
    name: string;
    type: string;
}

export interface UpdateTagGroupInput {
    name: string;
    nameEn: string;
}

export interface CreateTagInput {
    name: string;
    nameEn: string;
    tagGroupId: string;
}

export interface UpdateTagInput {
    name: string;
    nameEn: string;
}

export interface AddTagToTicketInput {
    tagId: string;
}

export interface CreateTransportLineInput {
    name: string;
    tagId: string;
}

export interface UpdateTransportLineInput {
    name: string;
    tagId: string;
}

export interface AddTicketsToTransportLinesInput {
    ticketIds: string[];
    transportLineIds: string[];
}

export interface CreateTransportZoneInput {
    name: string;
    referenceNumber: number;
    tags: string[];
}

export interface UpdateTransportZoneInput {
    name: string;
    referenceNumber: number;
    tags: string[];
}

export interface AddTicketsToTransportZonesInput {
    ticketIds: string[];
    transportZoneIds: string[];
}

export interface CreateCustomRangeInput {
    name: string;
    type: string;
    count?: number;
    duration?: string;
    fromDate?: Date[];
    toDate?: Date[];
    fromDay?: string[];
    toDay?: string[];
}

export interface UpdateCustomRangeInput {
    name?: string;
    type?: string;
    count?: number;
    fromDate?: string[];
    toDate?: string[];
    fromDay?: string[];
    toDay?: string[];
}

export interface LoginInput {
    email: string;
    password: string;
}

export interface OrderInput {
    orderItems: any[];
    name: string;
    paymentMethodId: string;
    language?: string;
    address?: string;
    city?: string;
    zip?: string;
    country?: string;
    phone?: string;
    dateOfBirth: string;
    deliveryMethod?: string;
    pickupStop?: string;
    companyName?: string;
    invoice?: boolean;
    vatNumber?: boolean;
}

export interface PolicyInput {
    description: string;
    descriptionEn: string;
    type: string;
    active?: boolean;
}

export interface CreateFaqInput {
    question: string;
    questionEn: string;
    type: string;
    answer: string;
    answerEn: string;
}

export interface UpdateFaqInput {
    question: string;
    questionEn: string;
    type: string;
    answer: string;
    answerEn: string;
}

export interface ReportRequestParams {
    from: Date;
    to: Date;
    type?: string;
    ticket?: string;
    product?: string;
    stan?: string;
}

export interface ReturnTicketParams {
    orderItemId: string;
    ticketCarrierId: string;
}

export interface GetOptimalTicketRequestParams {
    from: string;
    to: string;
    name: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseFormat;
    /** request body */
    body?: unknown;
    /** base url */
    baseUrl?: string;
    /** request cancellation token */
    cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
    baseUrl?: string;
    baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
    securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
    data: D;
    error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
    public baseUrl: string = '';
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private abortControllers = new Map<CancelToken, AbortController>();

    private baseApiParams: RequestParams = {
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    };

    constructor(appLang: string,
                apiConfig: ApiConfig<SecurityDataType> = { baseUrl: environment.apiUrl, }) {
        Object.assign(this, apiConfig);

        this.baseApiParams.headers = {
            ...this.baseApiParams.headers,
            'X-GPA-Lang': appLang
        };
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    private addQueryParam(query: QueryParamsType, key: string) {
        const value = query[key];

        return (
            encodeURIComponent(key) +
            '=' +
            encodeURIComponent(Array.isArray(value) ? value.join(',') : typeof value === 'number' ? value : `${value}`)
        );
    }

    protected toQueryString(rawQuery?: QueryParamsType): string {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
        return keys
            .map((key) =>
                typeof query[key] === 'object' && !Array.isArray(query[key])
                    ? this.toQueryString(query[key] as QueryParamsType)
                    : this.addQueryParam(query, key),
            )
            .join('&');
    }

    protected addQueryParams(rawQuery?: QueryParamsType): string {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : '';
    }

    private contentFormatters: Record<ContentType, (input: any) => any> = {
        [ContentType.Json]: (input: any) =>
            input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
        [ContentType.FormData]: (input: any) =>
            Object.keys(input || {}).reduce((data, key) => {
                data.append(key, input[key]);
                return data;
            }, new FormData()),
        [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
    };

    private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        };
    }

    private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                return abortController.signal;
            }
            return void 0;
        }

        const abortController = new AbortController();
        this.abortControllers.set(cancelToken, abortController);
        return abortController.signal;
    };

    public abortRequest = (cancelToken: CancelToken) => {
        const abortController = this.abortControllers.get(cancelToken);

        if (abortController) {
            abortController.abort();
            this.abortControllers.delete(cancelToken);
        }
    };

    public request = async <T = any, E = any>({
                                                  body,
                                                  secure,
                                                  path,
                                                  type,
                                                  query,
                                                  format = 'json',
                                                  baseUrl,
                                                  cancelToken,
                                                  ...params
                                              }: FullRequestParams): Promise<HttpResponse<T, E>> => {
        const secureParams = (secure && this.securityWorker && (await this.securityWorker(this.securityData))) || {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const queryString = query && this.toQueryString(query);
        const payloadFormatter = this.contentFormatters[type || ContentType.Json];

        return fetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
            ...requestParams,
            headers: {
                ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
                ...(requestParams.headers || {}),
            },
            signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
            body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
        }).then(async (response) => {
            const r = response as HttpResponse<T, E>;
            r.data = null as unknown as T;
            r.error = null as unknown as E;

            const data = await response[format]()
                .then((data) => {
                    if (r.ok) {
                        r.data = data;
                    } else {
                        r.error = data;
                    }
                    return r;
                })
                .catch((e) => {
                    r.error = e;
                    return r;
                });

            if (cancelToken) {
                this.abortControllers.delete(cancelToken);
            }

            if (!response.ok) throw data;
            return data;
        });
    };
}

/**
 * @title Mera Sklep API
 * @version 0.0.0
 * @contact
 */
@Injectable()
export class Api<SecurityDataType = any> extends HttpClient<SecurityDataType> {
    transportAuthorities = {
        /**
         * No description
         *
         * @tags transport-authorities
         * @name TransportAuthoritiesControllerGetAllTransportAuthorities
         * @request GET:/transport-authorities
         */
        transportAuthoritiesControllerGetAllTransportAuthorities: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/transport-authorities`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags transport-authorities
         * @name TransportAuthoritiesControllerGetTransportAuthorityById
         * @request GET:/transport-authorities/admin
         * @secure
         */
        transportAuthoritiesControllerGetTransportAuthorityById: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/transport-authorities/admin`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags transport-authorities
         * @name TpayControllerTpayResponse
         * @request POST:/transport-authorities/tpay-response
         */
        tpayControllerTpayResponse: (data: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/transport-authorities/tpay-response`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags transport-authorities
         * @name TpayControllerBuyTickets
         * @request POST:/transport-authorities/{transportAuthorityId}/order/create
         * @secure
         */
        tpayControllerBuyTickets: (transportAuthorityId: string, data: OrderInput, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/transport-authorities/${transportAuthorityId}/order/create`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),
    };
    users = {
        usersControllerGetSuppliers: (params: RequestParams = {}) =>
            this.request<any, any>({
                path: `/users/suppliers`,
                method: 'GET',
                ...params,
            }),

        usersControllerGetBusStopList: (params: RequestParams = {}) =>
            this.request<any, any>({
                path: `/users/busStopList`,
                method: 'GET',
                ...params
            }),

        usersControllerGetPickupPoints: (params: RequestParams = {}) =>
            this.request<any, any>({
                path: `/users/collect`,
                method: `GET`,
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerLogin
         * @request POST:/users/login
         */
        usersControllerLogin: (data: CredentialsInput, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/login`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerLogout
         * @request POST:/users/logout
         */
        usersControllerLogout: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/logout`,
                method: 'POST',
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerRefreshToken
         * @request GET:/users/refresh-token
         */
        usersControllerRefreshToken: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/refresh-token`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerRegister
         * @request POST:/users/register
         */
        usersControllerRegister: (data: RegistrationInput, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/register`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

      /**
       *
       * @name GuestsControllerRegister
       */
        guestsControllerRegister: (data: RegistrationInput, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/guest`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),
        /**
         * No description
         *
         * @tags users
         * @name UsersControllerGetMe
         * @request GET:/users/me
         * @secure
         */
        usersControllerGetMe: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/me`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerUpdatePassword
         * @request POST:/users/update-password
         * @secure
         */
        usersControllerUpdatePassword: (data: UpdatePasswordInput, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/update-password`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerUpdateEmail
         * @request POST:/users/update-email
         * @secure
         */
        usersControllerUpdateEmail: (data: UpdateEmailInput, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/update-email`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerApproveUser
         * @request GET:/users/approve/{token}
         */
        usersControllerApproveUser: (token: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/approve/${token}`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerResetPassword
         * @request POST:/users/reset-password
         * @secure
         */
        usersControllerResetPassword: (data: ResetPasswordInput, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/reset-password`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerCreateCustomerInfo
         * @request POST:/users/{userId}/customer-info
         * @secure
         */
        usersControllerCreateCustomerInfo: (userId: string, data: CustomerInfoInput, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/${userId}/customer-info`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerUpdateCustomerInfo
         * @request PATCH:/users/{userId}/customer-info/{customerInfoId}
         * @secure
         */
        usersControllerUpdateCustomerInfo: (
            customerInfoId: string,
            userId: string,
            data: CustomerInfoUpdateInput,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/users/${userId}/customer-info/${customerInfoId}`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerDeleteCustomerInfo
         * @request DELETE:/users/{userId}/customer-info/{customerInfoId}
         * @secure
         */
        usersControllerDeleteCustomerInfo: (customerInfoId: string, userId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/${userId}/customer-info/${customerInfoId}`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerCreateTicketCarrier
         * @request POST:/users/{userId}/ticket-carrier
         * @secure
         */
        usersControllerCreateTicketCarrier: (userId: string, data: TicketCarrierInput, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/${userId}/ticket-carrier`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerDeleteTicketCarrier
         * @request DELETE:/users/{userId}/ticket-carrier/{ticketCarrierId}
         * @secure
         */
        usersControllerDeleteTicketCarrier: (ticketCarrierId: string, userId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/${userId}/ticket-carrier/${ticketCarrierId}`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerUpdateTicketCarrier
         * @request PATCH:/users/{userId}/ticket-carrier/{ticketCarrierId}
         * @secure
         */
        usersControllerUpdateTicketCarrier: (
            ticketCarrierId: string,
            userId: string,
            data: UpdateTicketCarrierInput,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/users/${userId}/ticket-carrier/${ticketCarrierId}`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerGetTicketCarriers
         * @request GET:/users/{userId}/ticket-carriers
         * @secure
         */
        usersControllerGetTicketCarriers: (userId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/${userId}/ticket-carriers`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerConnectApprovedPolicies
         * @request POST:/users/{userId}/policies/approve
         * @secure
         */
        usersControllerConnectApprovedPolicies: (userId: string, data: string[], params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/${userId}/policies/approve`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerDeleteApprovedPolicies
         * @request DELETE:/users/{userId}/policies/reject
         * @secure
         */
        usersControllerDeleteApprovedPolicies: (userId: string, data: string[], params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/${userId}/policies/reject`,
                method: 'DELETE',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerGetAllPolicies
         * @request GET:/users/policies/get
         */
        usersControllerGetAllPolicies: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/policies/get`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerGetUserOrders
         * @request GET:/users/{userId}/orders
         * @secure
         */
        usersControllerGetUserOrders: (userId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/${userId}/orders`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerUpdateShoppingList
         * @request POST:/users/{userId}/shopping-list
         * @secure
         */
        usersControllerUpdateShoppingList: (userId: string, data: UpdateShoppingList, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/${userId}/shopping-list`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),
        /**
         * No description
         *
         * @tags users
         * @name UsersControllerClearShoppingList
         * @request DELETE:/users/{userId}/shopping-list
         * @secure
         */
        usersControllerClearShoppingList: (userId: string, data: ClearShoppingList,params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/${userId}/shopping-list`,
                method: 'DELETE',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),
        /**
         * No description
         *
         * @tags users
         * @name UsersControllerGetAllTickets
         * @request GET:/users/tickets/get/{transportAuthorityId}
         */
        usersControllerGetAllTickets: (transportAuthorityId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/tickets/get/${transportAuthorityId}`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name usersControllerGetAllTransportZones
         * @request GET:/users/zones/get/{transportAuthorityId}
         */
        usersControllerGetAllTransportZones: (transportAuthorityId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/zones/get/${transportAuthorityId}`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerGetFaq
         * @request GET:/users/faq/{transportAuthorityId}
         */
        usersControllerGetFaq: (transportAuthorityId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/faq/${transportAuthorityId}`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerGetTerms
         * @request GET:/users/terms/{transportAuthorityId}
         */
        usersControllerGetTerms: (transportAuthorityId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/terms/${transportAuthorityId}`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags users
         * @name UsersControllerGetInvoiceEmail
         * @request GET:/users/invoice-email
         */
        usersControllerGetInvoiceEmail: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/invoice-email`,
                method: 'GET',
                ...params,
            }),

        usersControllerCalculateReturnTicket: (body: ReturnTicketParams, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/return-ticket`,
                method: `POST`,
                body,
                ...params
            }),

        usersControllerCheckGuest: (guestEmai: string, params: RequestParams = {}) =>
            this.request<any>({
                path: `/users/guest-check/${guestEmai}`,
                method: `GET`,
                ...params
            }),


        usersControllerReturnTicket: (body: ReturnTicketParams, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/return-ticket`,
                method: `PATCH`,
                body,
                ...params
            })
    };
    mailer = {
        /**
         * No description
         *
         * @tags mailer
         * @name MailerControllerSendResetEmail
         * @request POST:/mailer/send-reset-email
         */
        mailerControllerSendResetEmail: (data: ResetEmailInput, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/mailer/send-reset-email`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags mailer
         * @name MailerControllerSendUpdateEmail
         * @request POST:/mailer/send-update-email
         * @secure
         */
        mailerControllerSendUpdateEmail: (data: SendUpdateEmailInput, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/mailer/send-update-email`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags mailer
         * @name MailerControllerSendContactEmail
         * @request POST:/mailer/contact-email
         */
        mailerControllerSendContactEmail: (data: ContactEmailInput, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/mailer/contact-email`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),
    };
    admin = {
        adminControllerGetServerTimezoneOffset : (params: RequestParams) => this.request<number, any>({
            path: `/admin/time`,
            method: `GET`,
            secure: true,
            format: 'text',
            ...params
        }),

        adminControllerEnableServiceMode: (body: ServiceModeBody,params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/setting/service-mode/update`,
                method: 'PATCH',
                secure: true,
                body,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerGetMe
         * @request GET:/admin/me
         * @secure
         */
        adminControllerGetMe: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/me`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerUpdateSystemConfiguration
         * @request PATCH:/admin/system-configuration
         * @secure
         */
        adminControllerUpdateSystemConfiguration: (data: SystemConfigurationInput, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/system-configuration`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerGetSystemConfiguration
         * @request GET:/admin/system-configuration
         * @secure
         */
        adminControllerGetSystemConfiguration: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/system-configuration`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerGetUsersOrders
         * @request GET:/admin/get-user-orders
         * @secure
         */
        adminControllerGetUsersOrders: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/get-users-orders`,
                method: 'GET',
                secure: true,
                // type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerResendEmail
         * @request POST:/admin/resend-email
         * @secure
         */
        adminControllerResendEmail:(data: UserOrder,params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/resend-email`,
                body: data,
                method: 'POST',
                secure: true,
                type: ContentType.Json,
                ...params,
            }),
        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerUpdateSystemConfigurationInvoiceEmail
         * @request PATCH:/admin/invoice-email
         * @secure
         */
        adminControllerUpdateSystemConfigurationInvoiceEmail: (
            data: UpdateInvoiceEmailInput,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/invoice-email`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerUpdateTerms
         * @request POST:/admin/transport-authority/{transportAuthorityId}/terms/
         * @secure
         */
        adminControllerUpdateTerms: (
            transportAuthorityId: string,
            data: UpdateTermsInput,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/terms/`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerGetFaq
         * @request GET:/admin/transport-authority/{transportAuthorityId}/faq
         * @secure
         */
        adminControllerGetFaq: (transportAuthorityId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/faq`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerCreateFaq
         * @request POST:/admin/transport-authority/{transportAuthorityId}/faq
         * @secure
         */
        adminControllerCreateFaq: (transportAuthorityId: string, data: CreateFaqInput, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/faq`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerDeleteFaq
         * @request DELETE:/admin/transport-authority/{transportAuthorityId}/faq{faqId}
         * @secure
         */
        adminControllerDeleteFaq: (faqId: string, transportAuthorityId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/faq${faqId}`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerUpdateFaq
         * @request POST:/admin/transport-authority/{transportAuthorityId}/faq/{faqId}
         * @secure
         */
        adminControllerUpdateFaq: (
            faqId: string,
            transportAuthorityId: string,
            data: UpdateFaqInput,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/faq/${faqId}`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerGetInvoices
         * @request GET:/admin/transport-authority/{transportAuthorityId}/orders/invoices/{fromDate}/{toDate}
         * @secure
         */
        adminControllerGetInvoices: (
            transportAuthorityId: string,
            toDate: string,
            fromDate: string,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/orders/invoices/${fromDate}/${toDate}`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerUpdateInvoiceDetails
         * @request POST:/admin/transport-authority/{transportAuthorityId}/invoice-details
         * @secure
         */
        adminControllerUpdateInvoiceDetails: (
            transportAuthorityId: string,
            data: InvoiceDetailsInput,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/invoice-details`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerGetAllTickets
         * @request GET:/admin/transport-authority/{transportAuthorityId}/tickets
         * @secure
         */
        adminControllerGetAllTickets: (transportAuthorityId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/tickets`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerCreateTicket
         * @request POST:/admin/transport-authority/{transportAuthorityId}/tickets
         * @secure
         */
        adminControllerCreateTicket: (
            transportAuthorityId: string,
            data: CreateTicketInput,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/tickets`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerCreateProduct
         * @request POST:/products
         * @secure
         */
        adminControllerCreateProduct: (
            body: UpdateProductInput,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/products`,
                method: 'POST',
                body,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        adminControllerCreateSupplier: (
            body: CreateSupplierInput,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/suppliers/create`,
                method: 'POST',
                body,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerUpdateTicket
         * @request PATCH:/admin/transport-authority/{transportAuthorityId}/tickets/{ticketId}
         * @secure
         */
        adminControllerUpdateTicket: (
            ticketId: string,
            transportAuthorityId: string,
            data: UpdateTicketInput,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/tickets/${ticketId}`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerUpdateProduct
         * @request PATCH:/products
         * @secure
         */
        adminControllerUpdateProduct: (
            productId: string,
            data: UpdateProductInput,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/products`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        adminControllerUpdateSupplier: (
            supplierId: string,
            data: UpdateSupplierInput,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/suppliers/${supplierId}`,
                method: `PATCH`,
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerDeleteTicket
         * @request DELETE:/admin/transport-authority/{transportAuthorityId}/tickets/{ticketId}
         * @secure
         */
        adminControllerDeleteTicket: (ticketId: string, transportAuthorityId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/tickets/${ticketId}`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerDeleteProduct
         * @request DELETE:/products/${productId}
         * @secure
         */
        adminControllerDeleteProduct: (productId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/products/${productId}`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        adminControllerDeleteSupplier: (supplierId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/suppliers/${supplierId}`,
                method: `DELETE`,
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerGetTagGroups
         * @request GET:/admin/transport-authority/{transportAuthorityId}/tag-groups
         * @secure
         */
        adminControllerGetTagGroups: (transportAuthorityId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/tag-groups`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerGetTagGroupByName
         * @request GET:/admin/transport-authority/{transportAuthorityId}/tag-groups/{tagGroupName}
         * @secure
         */
        adminControllerGetTagGroupByName: (
            tagGroupName: string,
            transportAuthorityId: string,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/tag-groups/${tagGroupName}`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerCreateTag
         * @request POST:/admin/transport-authority/{transportAuthorityId}/tags
         * @secure
         */
        adminControllerCreateTag: (transportAuthorityId: string, data: CreateTagInput, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/tags`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerGetTags
         * @request GET:/admin/transport-authority/{transportAuthorityId}/tags
         * @secure
         */
        adminControllerGetTags: (transportAuthorityId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/tags`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerGetTagById
         * @request GET:/admin/transport-authority/{transportAuthorityId}/tags/{tagId}
         * @secure
         */
        adminControllerGetTagById: (tagId: string, transportAuthorityId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/tags/${tagId}`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerUpdateTag
         * @request PATCH:/admin/transport-authority/{transportAuthorityId}/tags/{tagId}
         * @secure
         */
        adminControllerUpdateTag: (
            tagId: string,
            transportAuthorityId: string,
            data: UpdateTagInput,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/tags/${tagId}`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerDeleteTag
         * @request DELETE:/admin/transport-authority/{transportAuthorityId}/tags/{tagId}
         * @secure
         */
        adminControllerDeleteTag: (tagId: string, transportAuthorityId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/tags/${tagId}`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerDeleteTagFromTicket
         * @request DELETE:/admin/transport-authority/{transportAuthorityId}/tickets/{ticketId}/tags/{tagId}
         * @secure
         */
        adminControllerDeleteTagFromTicket: (
            transportAuthorityId: string,
            ticketId: string,
            tagId: string,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/tickets/${ticketId}/tags/${tagId}`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerAddTagToTicket
         * @request PATCH:/admin/transport-authority/{transportAuthorityId}/tickets/{ticketId}/assign-tag
         * @secure
         */
        adminControllerAddTagToTicket: (
            transportAuthorityId: string,
            ticketId: string,
            data: AddTagToTicketInput,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/tickets/${ticketId}/assign-tag`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerCreateTransportLine
         * @request POST:/admin/transport-authority/{transportAuthorityId}/transport-lines
         * @secure
         */
        adminControllerCreateTransportLine: (
            transportAuthorityId: string,
            data: CreateTransportLineInput,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/transport-lines`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerGetTransportLines
         * @request GET:/admin/transport-authority/{transportAuthorityId}/transport-lines
         * @secure
         */
        adminControllerGetTransportLines: (transportAuthorityId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/transport-lines`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerGetTransportLineById
         * @request GET:/admin/transport-authority/{transportAuthorityId}/transport-lines/{transportLineId}
         * @secure
         */
        adminControllerGetTransportLineById: (
            transportLineId: string,
            transportAuthorityId: string,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/transport-lines/${transportLineId}`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerDeleteTransportLine
         * @request DELETE:/admin/transport-authority/{transportAuthorityId}/transport-lines/{transportLineId}
         * @secure
         */
        adminControllerDeleteTransportLine: (
            transportLineId: string,
            transportAuthorityId: string,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/transport-lines/${transportLineId}`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerUpdateTransportLine
         * @request PATCH:/admin/transport-authority/{transportAuthorityId}/transport-lines/{transportLineId}
         * @secure
         */
        adminControllerUpdateTransportLine: (
            transportLineId: string,
            transportAuthorityId: string,
            data: UpdateTransportLineInput,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/transport-lines/${transportLineId}`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerAddTicketsToTransportLines
         * @request PATCH:/admin/transport-authority/{transportAuthorityId}/transport-lines/assign-tickets
         * @secure
         */
        adminControllerAddTicketsToTransportLines: (
            transportAuthorityId: string,
            data: AddTicketsToTransportLinesInput,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/transport-lines/assign-tickets`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerCreateTransportZone
         * @request POST:/admin/transport-authority/{transportAuthorityId}/transport-zones
         * @secure
         */
        adminControllerCreateTransportZone: (
            transportAuthorityId: string,
            data: CreateTransportZoneInput,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/transport-zones`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerGetTransportZones
         * @request GET:/admin/transport-authority/{transportAuthorityId}/transport-zones
         * @secure
         */
        adminControllerGetTransportZones: (transportAuthorityId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/transport-zones`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerGetTransportAreas
         * @request GET:/admin/transport-authority/{transportAuthorityId}/transport-areas
         * @secure
         */
        adminControllerGetTransportAreas: (transportAuthorityId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/transport-areas`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerGetTransportZoneById
         * @request GET:/admin/transport-authority/{transportAuthorityId}/transport-zones/{transportZoneId}
         * @secure
         */
        adminControllerGetTransportZoneById: (
            transportZoneId: string,
            transportAuthorityId: string,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/transport-zones/${transportZoneId}`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerDeleteTransportZone
         * @request DELETE:/admin/transport-authority/{transportAuthorityId}/transport-zones/{transportZoneId}
         * @secure
         */
        adminControllerDeleteTransportZone: (
            transportZoneId: string,
            transportAuthorityId: string,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/transport-zones/${transportZoneId}`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerUpdateTransportZone
         * @request PATCH:/admin/transport-authority/{transportAuthorityId}/transport-zones/{transportZoneId}
         * @secure
         */
        adminControllerUpdateTransportZone: (
            transportZoneId: string,
            transportAuthorityId: string,
            data: UpdateTransportZoneInput,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/transport-zones/${transportZoneId}`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerAddTicketsToTransportZones
         * @request PATCH:/admin/transport-authority/{transportAuthorityId}/transport-zones/assign-tickets
         * @secure
         */
        adminControllerAddTicketsToTransportZones: (
            transportAuthorityId: string,
            data: AddTicketsToTransportZonesInput,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/transport-zones/assign-tickets`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerCreateCustomRange
         * @request POST:/admin/transport-authority/{transportAuthorityId}/custom-ranges
         * @secure
         */
        adminControllerCreateCustomRange: (
            transportAuthorityId: string,
            data: CreateCustomRangeInput,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/custom-ranges`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerGetCustomRanges
         * @request GET:/admin/transport-authority/{transportAuthorityId}/custom-ranges
         * @secure
         */
        adminControllerGetCustomRanges: (transportAuthorityId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/custom-ranges`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerGetCustomRangeById
         * @request GET:/admin/transport-authority/{transportAuthorityId}/custom-ranges/{customRangeId}
         * @secure
         */
        adminControllerGetCustomRangeById: (
            customRangeId: string,
            transportAuthorityId: string,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/custom-ranges/${customRangeId}`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerDeleteCustomRange
         * @request DELETE:/admin/transport-authority/{transportAuthorityId}/custom-ranges/{customRangeId}
         * @secure
         */
        adminControllerDeleteCustomRange: (
            customRangeId: string,
            transportAuthorityId: string,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/custom-ranges/${customRangeId}`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerUpdateCustomRange
         * @request PATCH:/admin/transport-authority/{transportAuthorityId}/custom-ranges/{customRangeId}
         * @secure
         */
        adminControllerUpdateCustomRange: (
            customRangeId: string,
            transportAuthorityId: string,
            data: UpdateCustomRangeInput,
            params: RequestParams = {},
        ) =>
            this.request<void, any>({
                path: `/admin/transport-authority/${transportAuthorityId}/custom-ranges/${customRangeId}`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerCreatePolicy
         * @request POST:/admin/policy
         * @secure
         */
        adminControllerCreatePolicy: (data: PolicyInput, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/policy`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerGetPolicies
         * @request GET:/admin/policy
         * @secure
         */
        adminControllerGetPolicies: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/policy`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerDeletePolicy
         * @request DELETE:/admin/policy/{policyId}
         * @secure
         */
        adminControllerDeletePolicy: (policyId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/policy/${policyId}`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin
         * @name AdminControllerUpdatePolicy
         * @request POST:/admin/policy/{policyId}
         * @secure
         */
        adminControllerUpdatePolicy: (policyId: string, data: PolicyInput, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin/policy/${policyId}`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),
    };
    adminAuth = {
        /**
         * No description
         *
         * @tags admin-auth
         * @name AdminAuthControllerLogin
         * @request POST:/admin-auth/login
         * @secure
         */
        adminAuthControllerLogin: (data: LoginInput, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin-auth/login`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin-auth
         * @name AdminAuthControllerRefreshToken
         * @request GET:/admin-auth/refresh-token
         * @secure
         */
        adminAuthControllerRefreshToken: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin-auth/refresh-token`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags admin-auth
         * @name AdminAuthControllerLogout
         * @request POST:/admin-auth/logout
         * @secure
         */
        adminAuthControllerLogout: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/admin-auth/logout`,
                method: 'POST',
                secure: true,
                ...params,
            }),
    };
    pdfCreator = {
        /**
         * No description
         *
         * @tags pdf-creator
         * @name PdfCreatorControllerGetPdf
         * @request POST:/pdf-creator/create-pdf/{orderId}
         * @secure
         */
        pdfCreatorControllerGetPdf: (orderId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/pdf-creator/create-pdf/${orderId}`,
                method: 'POST',
                secure: true,
                ...params,
            }),
    };
    reports = {
        /**
         * No description
         *
         * @tags reports
         * @request GET:/reports
         * @secure
         */
        reportsControllerGetReport: ({ from, to, type, ticket, product, stan }: ReportRequestParams, params: RequestParams = {}) => {
            const path = [
                from.toISOString(),
                'to',
                to.toISOString(),
                type,
                ticket,
                product,
                stan,
            ]
                .map(value => value || ',')
                .reduce((acc, param) => acc!.concat('/', param!), '/reports')!;

            return this.request<void, any>({
                path,
                method: 'GET',
                secure: true,
                ...params,
            });
        },
    };
    payments = {
        /**
         * No description
         *
         * @tags payments
         * @name PaymentsControllerPaymentsResponse
         * @request POST:/payments/payments-response
         */
        paymentsControllerPaymentsResponse: (data: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/payments/payments-response`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags payments
         * @name PaymentsControllerBuyTickets
         * @request POST:/payments/transport-authorities/{transportAuthorityId}/order/create
         * @secure
         */
        paymentsControllerBuyTickets: (transportAuthorityId: string, data: OrderInput, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/payments/transport-authorities/${transportAuthorityId}/order/create`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags payments
         * @name PaymentsControllerCheckOrderStatus
         * @request GET:/payments/order-status/{orderId}
         * @secure
         */
        paymentsControllerCheckOrderStatus: (orderId: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/payments/order-status/${orderId}`,
                method: 'GET',
                secure: true,
                ...params,
            }),
    };
    products = {
        /**
         * No description
         *
         * @tags users
         * @name UsersControllerGetAllProducts
         * @request GET:/products
         */
        productsControllerAllProducts: (params: RequestParams = {}) =>
            this.request<any, any>({
                path: `/products`,
                method: 'GET',
                ...params,
            }),

        productsControllerAllSuppliers: (params: RequestParams = {}) =>
            this.request<any, any>({
                path: `/suppliers`,
                method: "GET",
                ...params,
            }),
    };
    distanceCalculator = {
        calculateDistanceBetween: ({ from, to }: { from: number; to: number }, params: RequestParams = {}) =>
            this.request<any, any>({
                path: `/distance-calculator/${from}/${to}`,
                method: 'GET',
                ...params,
            }),

        getOptimalTicket: (body: GetOptimalTicketRequestParams, params: RequestParams = {}) =>
            this.request<any, any>({
                path: `/distance-calculator/get-optimal-ticket`,
                method: `POST`,
                body,
                ...params,
            }),
    };

    constructor(@Inject(CURRENT_LANG) currentLang: string) {
        super(currentLang[0].toUpperCase() + currentLang.substring(1));
    }
}
